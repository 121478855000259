/* eslint no-console: 0 */
/* eslint prefer-spread: 0 */

import Bugsnag from '@bugsnag/js';

export default abstract class Logger {
    public static readonly LOG_PREFIX: string = '[VFSharePage]';

    public static info(...args: any[]): void {
        const args2 = Array.prototype.slice.call(args);
        args2.unshift(Logger.LOG_PREFIX);
        console.log.apply(console, args2);
    }

    public static error(...args: any[]): void {
        const args2 = Array.prototype.slice.call(args);
        args2.unshift(Logger.LOG_PREFIX);
        console.error.apply(console, args2);
    }

    public static reportError(error: any): void {
        Logger.error(error);
        Bugsnag.notify(error);
    }
}
