import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import Bugsnag from '@bugsnag/js';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { MaterialElevationDirective } from './directives/material-elevation.directive';
import { SharedModule } from './shared/shared.module';
import { inject } from '@vercel/analytics';

// Extend Window type with webkit property,
// required to check if iOS is running within a WKWebView browser instance.
declare global {
    interface Window {
        webkit?: any;
    }
}

const BUGSNAG_AGENT_URL = 'https://bugs-api.vuframe.com';
// configure Bugsnag
Bugsnag.start({
  apiKey: atob(environment.BUGSNAG_API_KEY),
  autoTrackSessions: false,
  appType: 'browser',
  appVersion: environment.version,
  releaseStage: environment.stage,
  // logger: null,
  endpoints: {
    notify: BUGSNAG_AGENT_URL,
    sessions: BUGSNAG_AGENT_URL
  },
  onError: (err) => {
    return err.errors[0]?.errorMessage !== "WebAR file not found.";
  }
})
Bugsnag.startSession();

// vercel analytics
inject();

// create a factory which will return the Bugsnag error handler
export function errorHandlerFactory() {
    return new BugsnagErrorHandler();
}

@NgModule({
    declarations: [
        AppComponent,
        MaterialElevationDirective
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        SharedModule,
        BrowserAnimationsModule,
        HttpClientModule,
        FormsModule,
    ],
    providers: [{ provide: ErrorHandler, useFactory: errorHandlerFactory }],
    bootstrap: [AppComponent]
})
export class AppModule {
}
