export const environment = {
      production: false,
      version: '3.0.3',
      stage: 'beta',
      identifier: 'beta',
      SHARE_BASE_URL: 'https://share.vuframe.com/',
      API_BASE_URL: 'https://api.vuframe.com/',
      CDN_BASE_URL: 'https://cdn.vuframe.com/',
      CLOUDFRONT_CDN: true,
      BUGSNAG_API_KEY: 'YTlmZDYwM2Y0MjUyZjM5NGIzNmRmMDQ4YzFjOGQ1YTQ=',
      API_TOKEN: 'Yzg3NDJkMjRlOGU0MmU5ZTg5MDYzMWFhMmY4NWI1NDk2NDVmYTg3YmQxOWQ2Yzk4NmE=',
      API_SECRET: 'OTRlZDI3MmY2YTU3NTJiMmNiM2ZjNGM5ODgyZThiNmNkNTUyMWIwMDhkNzgxZGI0NGU=',
      KEEN_PROJECT_ID: 'NWM0NmYyOWNjOWU3N2MwMDAxY2YxYzQ0',
      KEEN_WRITE_KEY: 'MkMyQTA0Mzc1MUMyMTExQjE4MEQ4RThENzMzRDhCQ0ZCNEI4QTA5NDg2OTRDOTg1MjQwNjUwRTE4REM5ODUyNjhEQkM2QjAyQUM4OEE2QjBCM0REMjhDNzk5QkMwRjA2QUFENUQ1NDNFNEU1QkYxNzVBNDY1NkZBNDE2MzhGMEMxOEQ4RkJGMEQ5NDQwMEM4NTczNDZCOUJDQ0VFNzhBMEQzRDhFMkY0N0YyRjlFREMyRjA2NjZBN0QwQzAxMzU2',
      SHARE_V2_URL: 'https://share2.vuframe.com/'
    };
    